import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { Tabs, Tab } from 'material-ui/Tabs';
import { ListItem } from 'material-ui/List';
import IconButton from 'material-ui/IconButton';
import { Card, CardTitle } from 'material-ui/Card';
import DatePicker from 'material-ui/DatePicker';
import Divider from 'material-ui/Divider';

import { Donut, Legend, Bar } from 'britecharts-react';

import { icons, colours } from '../../../theme';
import PlacementCard from '../../Placement/components/PlacementCard';
import HouseholdCard from '../../Household/components/HouseholdCard';
import PersonCard from '../../../components/PersonCard';
import GlobalLoading from '../../../components/GlobalLoading';
import { getStats, getCounts, getConcerns, getCIC } from '../AppActions';
import HeartLogo from '../../../components/HeartLogo';

import WWVP from '../components/WWVP';
import SCOC from '../components/SCOC';
import PC from '../components/PC';
import Review from '../components/Review';
import AttachmentMissing from '../components/AttachmentMissing';
import CIC from '../components/CIC';
import QOC from '../components/QOC';
import Incidents from '../components/Incidents';
import CSO from '../components/CSO';
import NoteAdd from '../../Placement/components/NoteAdd';

import ResponsiveContainer from '../../../util/ResponsiveContainer';

import './Dashboard.css';
import 'britecharts-react/dist/britecharts-react.min.css';

function getFYStart() {
  const curDate = new Date();
  if (curDate.getMonth() < 6)
    curDate.setFullYear(curDate.getFullYear() - 1, 6, 1);
  else curDate.setFullYear(curDate.getFullYear(), 6, 1);
  return curDate;
}

function getFYEnd() {
  const curDate = new Date();
  if (curDate.getMonth() >= 6)
    curDate.setFullYear(curDate.getFullYear() + 1, 5, 30);
  else curDate.setFullYear(curDate.getFullYear(), 5, 30);
  return curDate;
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const orgFrom = getFYStart();
    const orgTo = getFYEnd();
    this.state = {
      tab: 0,
      user: {},
      caseLoad: [],
      selectedPlacement: {},
      selectedHousehold: {},
      careType: '',
      orgFrom,
      orgTo,
      concernFrom: orgFrom,
      concernTo: orgTo,
      concernSection: 'Concerns in Care',
      showNoteAdd: false,
      noteAddId: '',
      noteAddType: '',
    };
    props
      .dispatch(getStats(orgFrom.toISOString(), orgTo.toISOString()))
      .then(stats => this.setState(stats));
    props
      .dispatch(getCIC(orgFrom.toISOString(), orgTo.toISOString()))
      .then(cic => this.setState(cic));
    props.dispatch(getCounts()).then(counts => this.setState(counts));
    props.dispatch(getConcerns()).then(concerns => this.setState(concerns));
  }
  tabChange(tab) {
    this.setState({ tab });
  }
  refetchCounts() {
    const { orgFrom, orgTo } = this.state;
    this.props
      .dispatch(getStats(orgFrom.toISOString(), orgTo.toISOString()))
      .then(stats => this.setState(stats));
  }
  refetchConcerns() {
    const { concernFrom, concernTo } = this.state;
    this.props
      .dispatch(getCIC(concernFrom.toISOString(), concernTo.toISOString()))
      .then(cic => this.setState(cic));
  }
  renderSelectedPlacement() {
    const { selectedPlacement, selectedHousehold } = this.state;
    if (!isEmpty(selectedPlacement)) {
      return (
        <div className="dash-col">
          <div style={{ backgroundColor: 'white' }}>
            <ListItem
              primaryText="View Placement"
              containerElement={<Link to={`/case/${selectedPlacement._id}`} />}
            />
          </div>
          <div style={{ backgroundColor: 'white' }}>
            <ListItem
              primaryText="Create Note"
              onClick={() =>
                this.setState({
                  showNoteAdd: true,
                  noteAddId: selectedPlacement._id,
                  noteAddType: 'case',
                })
              }
            />
          </div>
          <PersonCard person={selectedPlacement.youngPerson} rel="young" />
          <PersonCard
            person={selectedPlacement.household.primaryCarer}
            rel="carer"
            title="Primary Carer"
          />
          {selectedPlacement.household.secondaryCarer && (
            <PersonCard
              person={selectedPlacement.household.secondaryCarer}
              rel="carer"
              title="Secondary Carer"
            />
          )}
          <HouseholdCard household={selectedPlacement.household} />
          {selectedPlacement.household.otherIndividuals.map(o => (
            <PersonCard person={o} rel="other" key={o._id} />
          ))}
        </div>
      );
    }
    if (!isEmpty(selectedHousehold)) {
      return (
        <div className="dash-col">
          <div style={{ backgroundColor: 'white' }}>
            <ListItem
              primaryText="View Household"
              containerElement={
                <Link to={`/household/${selectedHousehold._id}`} />
              }
            />
          </div>
          <div style={{ backgroundColor: 'white' }}>
            <ListItem
              primaryText="Create Note"
              onClick={() =>
                this.setState({
                  showNoteAdd: true,
                  noteAddId: selectedHousehold._id,
                  noteAddType: 'household',
                })
              }
            />
          </div>
          <PersonCard
            person={selectedHousehold.primaryCarer}
            rel="carer"
            title="Primary Carer"
          />
          {selectedHousehold.secondaryCarer && (
            <PersonCard
              person={selectedHousehold.secondaryCarer}
              rel="carer"
              title="Secondary Carer"
            />
          )}
          {selectedHousehold.otherIndividuals.map(o => (
            <PersonCard person={o} rel="other" key={o._id} />
          ))}
        </div>
      );
    }
    return (
      <div className="dash-col">
        <div style={{ backgroundColor: 'white' }}>
          <ListItem primaryText="No Placement/Household Selected" disabled />
        </div>
      </div>
    );
  }
  renderOrganisation(colorSchema) {
    const { counts } = this.state || [];
    const {
      orgFrom,
      orgTo,
      careType,
      servicesProvided,
      carers,
      newRefs,
      acceptedRefs,
      carersGone,
      averagePlacementLength,
      casesOverFiveYears,
      respiteDays,
      highlightedServicesProvided,
      highlightedCareType,
    } = this.state;
    const datePickerSettings = {
      fullWidth: true,
      formatDate: d => moment(d).format('D MMMM YYYY'),
      autoOk: true,
      firstDayOfWeek: 1,
      style: { display: 'inline-block', width: '50%' },
      openToYearSelection: true,
    };
    const barChartSettings = {
      colorSchema,
      hasSingleBarHighlight: true,
      xTicks: 20,
      enableLabels: true,
      labelsNumberFormat: 'd',
      labelsMargin: 10,
    };
    return (
      <div className="dash-org">
        <div className="dash-counts">
          <Link to="/carer" className="dash-count">
            <icons.CarerIcon />
            <div>{counts && counts[0]}</div>
            <label>Active Carers</label>
          </Link>
          <Link to="/prospect" className="dash-count">
            <icons.ProspectIcon />
            <div>{counts && counts[1]}</div>
            <label>Open Prospects</label>
          </Link>
          <Link to="/young" className="dash-count">
            <icons.YoungPersonIcon />
            <div>{counts && counts[2]}</div>
            <label>Young People in Care</label>
          </Link>
          <Link to="/referral" className="dash-count">
            <icons.ReferralIcon />
            <div>{counts && counts[3]}</div>
            <label>Open Referrals</label>
          </Link>
        </div>
        <div className="dash-col-chart">
          <Card className="dash-chart">
            <CardTitle
              title={`${averagePlacementLength} days`}
              subtitle="Average Length of Current Foster Care Placements"
            />
            <CardTitle
              title={`${casesOverFiveYears?.toFixed(2)}% of Current Placements`}
              subtitle="stable for five years or more"
            />
          </Card>
          <Card className="dash-chart">
            <CardTitle subtitle="Number of Active Carer Households by Type" />
            <ResponsiveContainer
              render={({ width }) => (
                <div width={width} height={width / 1.5}>
                  <Donut
                    data={careType || []}
                    shouldShowLoadingState={!careType}
                    colorSchema={colorSchema}
                    width={width}
                    height={width / 1.5}
                    externalRadius={width / 3}
                    internalRadius={width / 6}
                    isAnimated={false}
                    customMouseOver={data =>
                      this.setState({ highlightedCareType: data.data.id })
                    }
                    customMouseOut={() =>
                      this.setState({
                        highlightedCareType: 99999,
                      })
                    }
                  />
                  <Legend
                    data={careType || []}
                    colorSchema={colorSchema}
                    numberFormat="d"
                    margin={{ top: 10, bottom: 10, left: 0, right: 30 }}
                    width={width}
                    height={careType ? careType.length * 33 : 33}
                    highlightEntryById={highlightedCareType}
                  />
                </div>
              )}
            />
          </Card>
          <Card className="dash-chart">
            <CardTitle subtitle="Placements by Services Provided" />
            <ResponsiveContainer
              render={({ width }) => (
                <div width={width} height={width / 1.5}>
                  <Bar
                    shouldShowLoadingState={!servicesProvided}
                    colorSchema={colorSchema}
                    width={width}
                    height={width / 1.5}
                    hasSingleBarHighlight
                    data={[...(servicesProvided || [])].reverse() || []}
                    labelsNumberFormat="d"
                    labelsSize={1}
                    isHorizontal
                    customMouseOver={data =>
                      this.setState({ highlightedServicesProvided: data.id })
                    }
                    customMouseOut={() =>
                      this.setState({
                        highlightedServicesProvided: 99999,
                      })
                    }
                  />
                  <Legend
                    data={
                      servicesProvided
                        ? servicesProvided.map(s => ({
                            ...s,
                            quantity: s.value,
                          }))
                        : []
                    }
                    colorSchema={colorSchema}
                    numberFormat="d"
                    margin={{ top: 10, bottom: 10, left: 0, right: 30 }}
                    width={width}
                    height={
                      servicesProvided ? servicesProvided.length * 33 : 33
                    }
                    highlightEntryById={highlightedServicesProvided}
                  />
                </div>
              )}
            />
          </Card>
        </div>
        <div className="dash-col-chart">
          <Card className="dash-chart">
            <div style={{ margin: '0 0.5%' }}>
              <DatePicker
                {...datePickerSettings}
                id="orgFrom"
                floatingLabelText="From"
                value={orgFrom}
                onChange={(e, orgFrom) =>
                  this.setState({ orgFrom }, () => this.orgTo.openDialog())
                }
              />
              <DatePicker
                ref={e => {
                  this.orgTo = e;
                }}
                {...datePickerSettings}
                id="orgTo"
                floatingLabelText="To"
                value={orgTo}
                onChange={(e, orgTo) =>
                  this.setState({ orgTo }, () => this.refetchCounts())
                }
              />
            </div>
            <CardTitle
              title={`${respiteDays} Total Nights in Respite`}
              subtitle="for period"
            />
          </Card>
          <Card className="dash-chart">
            <CardTitle subtitle="New Recruits" />
            <ResponsiveContainer
              render={({ width }) => (
                <div width={width} height={width / 2}>
                  <Bar
                    shouldShowLoadingState={!carers}
                    width={width}
                    height={width / 2}
                    data={carers || []}
                    {...barChartSettings}
                  />
                </div>
              )}
            />
            <Divider />
            <CardTitle subtitle="Carers No Longer Providing Care" />
            <ResponsiveContainer
              render={({ width }) => (
                <div width={width} height={width / 2}>
                  <Bar
                    shouldShowLoadingState={!carersGone}
                    width={width}
                    height={width / 2}
                    data={carersGone || []}
                    {...barChartSettings}
                  />
                </div>
              )}
            />
            <Divider />
            <CardTitle subtitle="New Referrals" />
            <ResponsiveContainer
              render={({ width }) => (
                <div width={width} height={width / 2}>
                  <Bar
                    shouldShowLoadingState={!newRefs}
                    width={width}
                    height={width / 2}
                    data={newRefs || []}
                    {...barChartSettings}
                  />
                </div>
              )}
            />
            <Divider />
            <CardTitle subtitle="Referrals Accepted" />
            <ResponsiveContainer
              render={({ width }) => (
                <div width={width} height={width / 2}>
                  <Bar
                    shouldShowLoadingState={!acceptedRefs}
                    width={width}
                    height={width / 2}
                    data={acceptedRefs || []}
                    {...barChartSettings}
                  />
                </div>
              )}
            />
          </Card>
        </div>
      </div>
    );
  }
  renderConcerns(colorSchema) {
    const {
      concernSection,
      wwvp,
      scoc,
      pc,
      review,
      safetyCheck,
      insuranceCover,
      ccp,
      slp,
      cic,
      incidents,
      concernFrom,
      concernTo,
      qoc,
      cso,
    } = this.state;
    const concerns = {
      'Concerns in Care': (
        <CIC
          cic={cic}
          from={concernFrom}
          to={concernTo}
          fromUpdate={from => this.setState({ concernFrom: from })}
          toUpdate={to =>
            this.setState({ concernTo: to }, () => this.refetchConcerns())
          }
        />
      ),
      'Quality of Care Issues': (
        <QOC
          qoc={qoc}
          from={concernFrom}
          to={concernTo}
          fromUpdate={from => this.setState({ concernFrom: from })}
          toUpdate={to =>
            this.setState({ concernTo: to }, () => this.refetchConcerns())
          }
        />
      ),
      Incidents: (
        <Incidents
          incidents={incidents}
          from={concernFrom}
          to={concernTo}
          fromUpdate={from => this.setState({ concernFrom: from })}
          toUpdate={to =>
            this.setState({ concernTo: to }, () => this.refetchConcerns())
          }
          colorSchema={colorSchema}
        />
      ),
      'No CSO Visits': (
        <CSO
          cso={cso}
          from={concernFrom}
          to={concernTo}
          fromUpdate={from => this.setState({ concernFrom: from })}
          toUpdate={to =>
            this.setState({ concernTo: to }, () => this.refetchConcerns())
          }
          colorSchema={colorSchema}
        />
      ),
      'WWVP Expired/Expiring': <WWVP wwvp={wwvp} />,
      'Safeguarding Children Online Certificate Expired/Expiring': (
        <SCOC scoc={scoc} />
      ),
      'Police Check Review Due': <PC pc={pc} />,
      'Carer Review Due': <Review review={review} />,
      'Household Safety Checks Missing': (
        <AttachmentMissing households={safetyCheck} />
      ),
      'Household Insurance Cover Missing': (
        <AttachmentMissing households={insuranceCover} />
      ),
      'Child Care Plan Missing': <AttachmentMissing youngPeople={ccp} />,
      'School Learning Plan Missing': <AttachmentMissing youngPeople={slp} />,
    };
    return (
      <div className="dash-org">
        <div className="dash-col">
          {map(concerns, (c, k) => (
            <ListItem
              key={k}
              primaryText={k}
              onClick={() => this.setState({ concernSection: k })}
              style={{ backgroundColor: 'white' }}
              rightIconButton={
                <IconButton
                  onClick={() =>
                    this.setState({
                      concernSection: k === concernSection ? '' : k,
                    })
                  }
                >
                  {k === concernSection ? (
                    <icons.ChevronLeftIcon />
                  ) : (
                    <icons.ChevronRightIcon />
                  )}
                </IconButton>
              }
            />
          ))}
        </div>
        {concerns[concernSection] || (
          <div className="dash-col" style={{ backgroundColor: 'white' }} />
        )}
      </div>
    );
  }
  renderPlacement(caseLoad) {
    const { selectedPlacement } = this.state;
    return (
      <PlacementCard
        caseLoad={caseLoad}
        key={caseLoad._id}
        rightIconButton={
          <IconButton
            onClick={() =>
              this.setState({
                selectedPlacement:
                  selectedPlacement._id === caseLoad._id ? {} : caseLoad,
                selectedHousehold: {},
              })
            }
          >
            {selectedPlacement._id === caseLoad._id ? (
              <icons.ChevronLeftIcon />
            ) : (
              <icons.ChevronRightIcon />
            )}
          </IconButton>
        }
        onClick={() =>
          this.setState({
            selectedPlacement:
              selectedPlacement._id === caseLoad._id ? {} : caseLoad,
            selectedHousehold: {},
          })
        }
      />
    );
  }
  renderHousehold(household) {
    const { selectedHousehold } = this.state;
    return (
      <HouseholdCard
        household={household}
        key={household._id}
        rightIconButton={
          <IconButton
            onClick={() =>
              this.setState({
                selectedHousehold:
                  selectedHousehold._id === household._id ? {} : household,
                selectedPlacement: {},
              })
            }
          >
            {selectedHousehold._id === household._id ? (
              <icons.ChevronLeftIcon />
            ) : (
              <icons.ChevronRightIcon />
            )}
          </IconButton>
        }
        onClick={() =>
          this.setState({
            selectedHousehold:
              selectedHousehold._id === household._id ? {} : household,
            selectedPlacement: {},
          })
        }
      />
    );
  }
  renderPlacementLoad() {
    const { user } = this.props;
    const caseLoad = user.staff ? user.staff.caseLoad : [];
    const currentPlacements = caseLoad.filter(
      c => !c.leftCareDate || moment(c.leftCareDate).isAfter(moment())
    );
    const archivedPlacements = caseLoad.filter(
      c => c.leftCareDate && moment(c.leftCareDate).isBefore(moment())
    );
    const households = user.staff ? user.staff.households : [];
    return (
      <div className="dash-caseload">
        <div className="dash-col">
          {caseLoad.length ? (
            [
              currentPlacements.length ? (
                <div style={{ backgroundColor: 'white' }}>
                  <ListItem
                    key="current"
                    primaryText="Current Placements"
                    disabled
                  />
                </div>
              ) : null,
              ...currentPlacements.map(s => this.renderPlacement(s)),
              archivedPlacements.length ? (
                <div style={{ backgroundColor: 'white' }}>
                  <ListItem
                    key="archived"
                    primaryText="Archived Placements"
                    disabled
                  />
                </div>
              ) : null,
              ...archivedPlacements.map(s => this.renderPlacement(s)),
            ]
          ) : (
            <div style={{ backgroundColor: 'white' }}>
              <ListItem primaryText="No Placements" disabled />
            </div>
          )}
          <div style={{ backgroundColor: 'white' }}>
            <ListItem
              primaryText={households.length ? 'Households' : 'No Households'}
              disabled
            />
          </div>
          {households.map(h => this.renderHousehold(h))}
        </div>
        {caseLoad.length || households.length ? (
          this.renderSelectedPlacement()
        ) : (
          <div className="dash-col">
            <div style={{ backgroundColor: 'white' }}>
              <ListItem primaryText="No Placement Selected" disabled />
            </div>
          </div>
        )}
      </div>
    );
  }
  render() {
    const { user } = this.props;
    if (isEmpty(user)) return <GlobalLoading />;
    const colorSchema = [
      colours.skyBlue,
      colours.limeGreen,
      colours.steelBlue,
      colours.lightSkyBlue,
      colours.paleBlue,
      colours.kGrey,
    ];
    const {
      tab,
      showNoteAdd,
      selectedPlacement,
      selectedHousehold,
    } = this.state;
    const { isMobile } = this.context;
    return (
      <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dashboard - HEART1869</title>
          <meta name="description" content="Kennerley HEART1869 Dashboard" />
        </Helmet>
        <div className="dash-content">
          <div className="list-head-tabs">
            <Tabs
              className="tabs"
              value={tab}
              onChange={value => this.tabChange(value)}
            >
              <Tab
                value={0}
                label={!isMobile() ? 'Placements' : ''}
                icon={<icons.PlacementIcon />}
              />
              <Tab
                value={1}
                label={!isMobile() ? 'Organisation' : ''}
                icon={<HeartLogo />}
              />
              <Tab
                value={2}
                label={!isMobile() ? 'Concerns' : ''}
                icon={<icons.AlertIcon />}
              />
            </Tabs>
          </div>
          <div className="dash-body">
            {tab === 0 && this.renderPlacementLoad()}
            {tab === 1 && this.renderOrganisation(colorSchema)}
            {tab === 2 && this.renderConcerns(colorSchema)}
          </div>
        </div>
        <NoteAdd
          closeDialog={() => this.setState({ showNoteAdd: false })}
          open={showNoteAdd}
          {...(!isEmpty(selectedPlacement)
            ? {
                caseLoad: selectedPlacement,
                accommodation: selectedPlacement.accommodation,
                youngPerson: selectedPlacement.youngPerson,
                ...(selectedPlacement.household
                  ? {
                      primaryCarer: selectedPlacement.household.primaryCarer,
                      secondaryCarer:
                        selectedPlacement.household.secondaryCarer,
                      householdMembers:
                        selectedPlacement.household.otherIndividuals,
                    }
                  : {}),
              }
            : {})}
          {...(!isEmpty(selectedHousehold)
            ? {
                household: selectedHousehold,
                primaryCarer: selectedHousehold.primaryCarer,
                secondaryCarer: selectedHousehold.secondaryCarer,
                householdMembers: selectedHousehold.otherIndividuals,
              }
            : {})}
          staff={user.staff}
        />
      </div>
    );
  }
}

Dashboard.contextTypes = {
  isMobile: PropTypes.func.isRequired,
  isTablet: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.profile,
  message: state.app.message,
  caseLoad: state.user.profile.staff ? state.user.profile.staff.caseLoad : [],
});

export default connect(mapStateToProps)(Dashboard);
